import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {},
  menuContainer: {
    padding: '50px 25px 10px',
  },
}));

export default useStyles;
