import { ANIM } from '@constants';

export const colorMaskVariants = ({ delay, thickness, vertical }) => ({
  visible: { height: '100%', width: '100%' },
  revealed: {
    height: vertical ? '100%' : `${thickness}px`,
    width: vertical ? `${thickness}px` : '100%',
    transition: {
      duration: ANIM.duration,
      delay: (ANIM.duration) * 0.5 + delay,
      ease: ANIM.projectThumbnailList.easeMaskReveal,
    },
  },
});
export const whiteMaskVariants = ({ delay, vertical }) => ({
  visible: { height: '100%', width: '100%' },
  revealed: {
    height: vertical ? '100%' : '0%',
    width: vertical ? '0%' : '100%',
    transition: {
      duration: ANIM.duration,
      delay,
      ease: ANIM.projectThumbnailList.easeMaskReveal,
    },
  },
});
