import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import useAnimationContext from '@utils/useAnimationContext';
import { ANIM } from '@constants';

const DanceInText = ({ children, keys, delay }) => {
  const { enabled: hasAnimation, triggered: animationTriggered } = useAnimationContext();

  return (
    <>
      {children.split('').map((char, index) => (
        <motion.span
          key={keys[index]}
          animate={{
            y: !hasAnimation || animationTriggered ? 0 : '2em',
          }}
          transition={{
            delay: delay + index * 0.05,
            duration: 1.0,
            ease: ANIM.easeOutQuick,
          }}
          style={{ display: 'inline-block' }}
        >
          <motion.span
            animate={{
              opacity: !hasAnimation || animationTriggered ? 1 : 0,
            }}
            transition={{
              delay: delay + index * 0.05,
              duration: 0.01,
            }}
          >
            {char === ' ' ? '\u00A0' : char}
          </motion.span>
        </motion.span>
      ))}
    </>
  );
};

DanceInText.defaultProps = {
  delay: 0,
};

DanceInText.propTypes = {
  children: PropTypes.string.isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  delay: PropTypes.number,
};

export default DanceInText;
