import React from 'react';
import PropTypes from 'prop-types';
import useBreakpoints from '@utils/useBreakpoints';
import SectionTitle from '@components/common/SectionTitle';
import ProjectThumbnailList from '@components/project-list/ProjectThumbnailList';
import CTAButton from '@components/common/CTAButton';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import useInViewAnimation from '@utils/useInViewAnimation';
import FadeSlideUp from '@components/common/FadeSlideUp';
import useStyles from './styles';
import useData from './data';

const FeaturedProjectsSection = ({ title, projects }) => {
  const data = useData();
  const featuredProjects = data.featuredProjects.edges[0].node.featuredProjects;
  const classes = useStyles();
  const { mdDown, smDown } = useBreakpoints();
  const {
    context,
    AnimationContext,
    intersectionRef,
  } = useInViewAnimation({ threshold: 0.5 });

  return (
    <AnimationContext.Provider value={context}>
      <section
        ref={intersectionRef}
        className={classes.root}
      >
        <SectionTitle>{ title }</SectionTitle>
        <ProjectThumbnailList
          xs={12}
          md={4}
          horizontalOnMobile
          projects={projects || featuredProjects}
        />
        <div className={classes.buttonContainer}>
          <FadeSlideUp delay={0.6}>
            <CTAButton
              label="SEE MORE"
              width={smDown ? '200px' : mdDown ? '220px' : '250px'}
              to="/projects"
              noLine
            />
          </FadeSlideUp>
        </div>
      </section>
    </AnimationContext.Provider>
  );
};

FeaturedProjectsSection.defaultProps = {
  title: 'FEATURED PROJECTS',
  projects: null,
};

FeaturedProjectsSection.propTypes = {
  title: PropTypes.string,
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    titleShort: PropTypes.string,
    clientName: PropTypes.string.isRequired,
    clientNameShort: PropTypes.string,
    primaryImage: PropTypes.shape({
      title: PropTypes.string,
      fluid: GatsbyImagePropTypes.fluid,
    }).isRequired,
  })),
};

export default FeaturedProjectsSection;
