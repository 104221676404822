import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: (props) => props.margin,
    display: (props) => (props.inline ? 'inline-block' : 'block'),
  },
  container: {
    position: 'relative',
    display: 'inline-block',
  },
  labelWrapper: {
    overflow: 'hidden',
    cursor: 'pointer',
    position: 'relative',
    marginBottom: '10px',
  },
  label: {
    position: 'relative',
    display: 'inline-block',
    color: (props) => props.color,
    '&:before': {
      content: (props) => `"${props.label}"`,
      color: (props) => props.hoverColor,
      position: 'absolute',
      top: '-100%',
    },
    fontFamily: '"DM Sans", sans-serif',
    fontSize: '2rem',
    fontWeight: 'bold',
    lineHeight: '3rem',
    letterSpacing: '0.1rem',
  },
  underline: {
    position: 'absolute',
    left: '50%',
    bottom: '0',
    transform: 'translateX(-50%)',
    height: '4px',
    backgroundColor: (props) => props.hoverColor,
    [theme.breakpoints.down('sm')]: {
      height: '2px',
      bottom: '8px',
    },
  },
}));

export default useStyles;
