import React from 'react';
import PropTypes from 'prop-types';
import useBreakpoints from '@utils/useBreakpoints';
import { Link } from 'gatsby';
import MoeyLogo from '@components/common/MoeyLogo';
import useHeaderState from '@utils/useHeaderState';
import Container from './AppHeaderContainer';
import Inner from './AppHeaderInner';
import HeaderNavMenu from '../HeaderNavMenu';
import MobileHeaderMenuIcon from '../MobileHeaderMenuIcon';
import useStyles from './styles';

const AppHeader = ({ handleClickOnMenuIcon, mobileMenuIsOpen }) => {
  const {
    menuType,
    menuIsVisible,
    wasAtPageTop,
    color,
    accentColor,
    backgroundColor,
    menuHeight,
  } = useHeaderState();

  const { smDown, xsDown } = useBreakpoints();
  const classes = useStyles();

  return (
    <Container
      fixed={menuType === 'sticky' || mobileMenuIsOpen}
      show={mobileMenuIsOpen || menuIsVisible}
      hasTransition={menuType === 'sticky' && !wasAtPageTop}
    >
      <Inner
        hasTransition={!mobileMenuIsOpen}
        backgroundColor={mobileMenuIsOpen ? 'transparent' : backgroundColor}
        height={`${menuHeight}px`}
      >
        <Link to="/" className={classes.logoLink}>
          <MoeyLogo
            width={xsDown ? '70px' : smDown ? '80px' : '120px'}
            fill={mobileMenuIsOpen ? 'white' : accentColor}
          />
        </Link>
        {smDown ? (
          <MobileHeaderMenuIcon
            fill={mobileMenuIsOpen ? 'white' : accentColor}
            mobileMenuIsOpen={mobileMenuIsOpen}
            onClick={handleClickOnMenuIcon}
          />
        ) : (
          <HeaderNavMenu
            color={color}
            accentColor={accentColor}
          />
        )}
      </Inner>
    </Container>
  );
};

AppHeader.defaultProps = {
  mobileMenuIsOpen: false,
};

AppHeader.propTypes = {
  handleClickOnMenuIcon: PropTypes.func.isRequired,
  mobileMenuIsOpen: PropTypes.bool,
};

export default AppHeader;
