import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import useAnimationContext from '@utils/useAnimationContext';
import { ANIM } from '@constants';

const FadeSlideUp = ({
  display,
  delay,
  offset,
  children,
  translateDuration,
  opacityDuration,
}) => {
  const { enabled: hasAnimation, triggered: animationTriggered } = useAnimationContext();

  return (
    <motion.span
      animate={{ y: !hasAnimation || animationTriggered ? 0 : offset }}
      transition={{
        delay,
        duration: translateDuration,
        ease: ANIM.easeOutCubic,
      }}
      style={{ display }}
    >
      <motion.span
        animate={{ opacity: !hasAnimation || animationTriggered ? 1 : 0 }}
        transition={{
          delay,
          duration: opacityDuration,
          ease: 'easeInOut',
        }}
        style={{ display }}
      >
        { children }
      </motion.span>
    </motion.span>
  );
};

FadeSlideUp.defaultProps = {
  display: 'inline-block',
  delay: 0,
  offset: '30%',
  translateDuration: 0.6,
  opacityDuration: 0.2,
};

FadeSlideUp.propTypes = {
  display: PropTypes.oneOf(['inline-block', 'block']),
  delay: PropTypes.number,
  offset: PropTypes.string,
  children: PropTypes.node.isRequired,
  translateDuration: PropTypes.number,
  opacityDuration: PropTypes.number,
};

export default FadeSlideUp;
