import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const AppHeaderInner = ({ children, hasTransition, backgroundColor, height }) => {
  const classes = useStyles({ hasTransition, backgroundColor, height });

  return (
    <header className={classes.header}>
      <div className={classes.container}>
        { children }
      </div>
    </header>
  );
};

AppHeaderInner.defaultProps = {
  hasTransition: false,
  backgroundColor: '',
};

AppHeaderInner.propTypes = {
  children: PropTypes.node.isRequired,
  hasTransition: PropTypes.bool,
  backgroundColor: PropTypes.string,
  height: PropTypes.string.isRequired,
};

export default AppHeaderInner;
