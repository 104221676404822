import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  menuIcon: {
    width: '26px',
    height: 'auto',
    fill: (props) => (props.fill),
    cursor: 'pointer',
  },
  closeIcon: {
    width: '25.46px',
    height: 'auto',
    fill: (props) => (props.fill),
    cursor: 'pointer',
  },
}));

export default useStyles;
