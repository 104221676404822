import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Hidden } from '@material-ui/core';
import useBreakpoints from '@utils/useBreakpoints';
import MoeyLogo from '@components/common/MoeyLogo';
import SocialMediaIconContainer from '@components/common/SocialMediaIconContainer';
import { PALETTE } from '@constants';
import MoeyAddress from './MoeyAddress';
import MoeyContact from './MoeyContact';
import FooterButton from './FooterButton';
import FooterNote from './FooterNote';
import useStyles from './styles';

const AppFooter = ({ noLogo, whiteText, noFooterNote, noFooterButton }) => {
  const classes = useStyles();
  const { smDown } = useBreakpoints();

  return (
    <footer className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={smDown ? 4 : 6}
      >
        {noLogo ? null : (
          <Grid item xs={12} md={3} lg={2}>
            <MoeyLogo
              width="120px"
              fill={PALETTE.moeyBlue}
              className={classes.moeyLogo}
            />
          </Grid>
        )}
        <Grid item xs={12} md={3} lg={2}>
          <MoeyAddress
            color={whiteText ? 'textSecondary' : 'textPrimary'}
            addressInThreeLines={!smDown}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <MoeyContact
            color={whiteText ? 'textSecondary' : 'textPrimary'}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item lg={2} />
        </Hidden>
        <Grid item xs={12} md={3} lg={2}>
          <SocialMediaIconContainer
            facebook
            twitter
            youtube
            facebookURL="https://www.facebook.com/moeyinc/"
            twitterURL="https://twitter.com/moeyinc"
            iconWidth={36}
            gap={18}
            className={classes.socialMediaIconContainer}
          />
        </Grid>
      </Grid>
      {noFooterButton ? null : (
        <FooterButton
          textAlign={smDown ? 'left' : 'center'}
          className={classes.footerButton}
          subscriptionForm="http://eepurl.com/h3L5fL"
          label="Subscribe to our newsletter"
        />
      )}
      {noFooterNote ? null : (
        <FooterNote
          color={whiteText ? 'textSecondary' : 'textPrimary'}
          textAlign={smDown ? 'left' : 'center'}
          className={classes.footerNote}
        />
      )}
    </footer>
  );
};

AppFooter.defaultProps = {
  noLogo: false,
  whiteText: false,
  noFooterNote: false,
  noFooterButton: false,
};

AppFooter.propTypes = {
  noLogo: PropTypes.bool,
  whiteText: PropTypes.bool,
  noFooterNote: PropTypes.bool,
  noFooterButton: PropTypes.bool,
};

export default AppFooter;
