import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useBreakpoints from '@utils/useBreakpoints';
import useStyles from './styles';

const ProjectThumbnailListItemText = ({
  title,
  client,
  gradientColorLight,
  gradientColorDark,
  horizontalOnMobile,
}) => {
  const { smDown } = useBreakpoints();
  const vertical = horizontalOnMobile && smDown;
  const thickness = smDown ? 8 : 10;
  const classes = useStyles({
    horizontalOnMobile,
    vertical,
    thickness,
    gradientColorLight,
    gradientColorDark,
  });

  return (
    <div className={classes.textContainer}>
      <Typography
        variant="body2"
        className={classes.clientName}
        style={{ color: gradientColorDark }}
      >
        { client }
      </Typography>
      <Typography variant="h4">
        { title }
      </Typography>
    </div>
  );
};

ProjectThumbnailListItemText.defaultProps = {
  horizontalOnMobile: false,
};

ProjectThumbnailListItemText.propTypes = {
  title: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  gradientColorLight: PropTypes.string.isRequired,
  gradientColorDark: PropTypes.string.isRequired,
  horizontalOnMobile: PropTypes.bool,
};

export default ProjectThumbnailListItemText;
