import { makeStyles } from '@material-ui/core/styles';
import { PALETTE } from '@constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '100px 70px 80px',
    [theme.breakpoints.down('sm')]: {
      padding: '50px 30px 60px',
    },
  },
  moeyLogo: {
    marginBottom: '10px',
  },
  socialMediaIconContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
  },
  footerButton: {
    marginTop: '130px',
    backgroundColor: PALETTE.moeyBlue,
    border: 'transparent',
    borderRadius: '28px',
    padding: '8px 25px 8px 25px',
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
      marginTop: '120px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
    },
    '&:hover': {
      backgroundColor: PALETTE.moeyBlue,
    },
  },
  footerNote: {
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '25px',
    },
  },
}));

export default useStyles;
