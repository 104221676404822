import { Typography } from '@material-ui/core';
import React, { useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import GatsbyBackgroundImage from 'gatsby-background-image';
import useInViewAnimation from '@utils/useInViewAnimation';
import DanceInText from '@components/common/DanceInText';
import NeonVideo from '@images/neon-home.webm';
import useStyles from './styles';
import useData from './data';

const ContactBannerSection = () => {
  const data = useData();
  const contactBannerImage = data.contactBannerImage.childImageSharp.fluid;
  const classes = useStyles();
  const {
    context,
    AnimationContext,
    intersectionRef,
    triggered,
  } = useInViewAnimation({ threshold: 0 });

  const videoRef = useRef();
  useEffect(() => {
    if (triggered) {
      videoRef.current.play();
    }
  }, [triggered]);

  return (
    <AnimationContext.Provider value={context}>
      <section
        className={classes.root}
        ref={intersectionRef}
      >
        <div className={classes.neonImageContainer}>
          <video
            ref={videoRef}
            muted
            playsInline
            preload="auto"
            className={classes.neonVideo}
          >
            <source src={NeonVideo} type="video/webm" />
          </video>
        </div>
        <Link to="/contact" className="no-decoration">
          <div className={classes.imageBannerContainer}>
            <GatsbyBackgroundImage
              className={classes.imageBanner}
              fluid={contactBannerImage}
              fadeIn={false}
            >
              <Typography variant="h2" color="textSecondary">
                <DanceInText
                  delay={0.5}
                  keys={'CONTACT US'.split('').map((char, index) => `${char}-${index}`)}
                >
                  CONTACT US
                </DanceInText>
              </Typography>
            </GatsbyBackgroundImage>
          </div>
        </Link>
      </section>
    </AnimationContext.Provider>
  );
};

export default ContactBannerSection;
