import React from 'react';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import ProjectThumbnailListItemImageMask from './ProjectThumbnailListItemImageMask';
import useStyles from './styles';

const ProjectThumbnailListItemImage = ({
  image,
  horizontalOnMobile,
  gradientColorLight,
  gradientColorDark,
  delay,
}) => {
  const classes = useStyles({ horizontalOnMobile });

  return (
    <div className={classes.root}>
      <ProjectThumbnailListItemImageMask
        horizontalOnMobile={horizontalOnMobile}
        gradientColorLight={gradientColorLight}
        gradientColorDark={gradientColorDark}
        delay={delay}
      />
      <div className={classes.thumbnailWrapper}>
        <div className={classes.thumbnail}>
          <GatsbyImage
            fluid={image}
            alt="featured-project-thumbnail"
            style={{ height: '100%' }}
            imgStyle={{ objectPosition: 'center top' }}
          />
        </div>
      </div>
    </div>
  );
};

ProjectThumbnailListItemImage.defaultProps = {
  horizontalOnMobile: false,
  delay: 0,
};

ProjectThumbnailListItemImage.propTypes = {
  image: GatsbyImagePropTypes.fluid.isRequired,
  horizontalOnMobile: PropTypes.bool,
  gradientColorLight: PropTypes.string.isRequired,
  gradientColorDark: PropTypes.string.isRequired,
  delay: PropTypes.number,
};

export default ProjectThumbnailListItemImage;
