import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { ANIM } from '@constants';
import useStyles from './styles';
import { labelVariants, underlineVariants } from './animations';

const HeaderNavMenuItem = ({ color, hoverColor, to, label, selected, onClick, inline, margin }) => {
  const classes = useStyles({ color, hoverColor, label, selected, inline, margin });
  const transition = { duration: ANIM.duration, ease: 'easeInOut' };

  return (
    <div className={classes.root}>
      <Link to={to} className="no-decoration" onClick={onClick}>
        <motion.div
          className={classes.container}
          animate={selected ? 'selected' : null}
          initial="rest"
          whileHover="hover"
          transition={transition}
        >
          <div className={classes.labelWrapper}>
            <motion.span
              className={classes.label}
              variants={labelVariants}
              transition={transition}
            >
              {label}
            </motion.span>
          </div>
          <motion.div
            className={classes.underline}
            variants={underlineVariants}
            transition={transition}
          />
        </motion.div>
      </Link>
    </div>
  );
};

HeaderNavMenuItem.defaultProps = {
  onClick: () => {},
  inline: false,
  margin: '',
};

HeaderNavMenuItem.propTypes = {
  color: PropTypes.string.isRequired,
  hoverColor: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  inline: PropTypes.bool,
  margin: PropTypes.string,
};

export default HeaderNavMenuItem;
