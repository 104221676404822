import { useStaticQuery, graphql } from 'gatsby';

const useData = () => useStaticQuery(graphql`
  query {
    featuredProjects: allContentfulFeaturedProjects(limit: 1) {
      edges {
        node {
          featuredProjects {
            id
            title
            titleShort
            slug
            clientName
            clientNameShort
            gradientColorLight
            gradientColorDark
            primaryImage {
              title
              fluid(maxWidth: 640) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`);

export default useData;
