import { useStaticQuery, graphql } from 'gatsby';

const useData = () => useStaticQuery(graphql`
  query {
    contactBannerImage: file(relativePath: {eq: "contact-banner-bg@2x.png"}) {
      childImageSharp {
        fluid(maxHeight: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`);

export default useData;
