import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useWindowScrollValues from '@utils/useWindowScrollValues';
import useHeaderMenuHeight from '@utils/useHeaderMenuHeight';
import { PALETTE } from '@constants';

const ColorInterpolate = require('color-interpolate');

export default function useHeaderState() {
  const { scrollY, lastScrollY, isScrollingUp } = useWindowScrollValues();
  const [menuType, setMenuType] = useState('top');
  const [menuIsVisible, setMenuIsVisible] = useState(true);
  const [color, setColor] = useState('white');
  const [accentColor, setAccentColor] = useState('white');
  const [backgroundOpacity, setBackgroundOpacity] = useState(0);
  const menuHeight = useHeaderMenuHeight();

  const whiteToMoeyBlue = ColorInterpolate(['white', PALETTE.moeyBlue]);
  const whiteToGrey = ColorInterpolate(['white', PALETTE.text.primary]);

  const updateValues = (_menuType, _menuIsVisible, _color, _accentColor, _backgroundOpacity) => {
    if (_menuType !== menuType) setMenuType(_menuType);
    if (_menuIsVisible !== menuIsVisible) setMenuIsVisible(_menuIsVisible);
    if (_color !== color) setColor(_color);
    if (_accentColor !== accentColor) setAccentColor(_accentColor);
    if (_backgroundOpacity !== backgroundOpacity) setBackgroundOpacity(_backgroundOpacity);
  };

  const ratio = scrollY / menuHeight;
  const isAtPageTop = (scrollY <= menuHeight);
  const wasAtPageTop = (lastScrollY <= menuHeight);
  const isModalOpen = useSelector((state) => state.isImageGalleryModalOpen);
  useEffect(() => {
    if (isModalOpen) {
      setMenuIsVisible(false);
    } else if (isScrollingUp) {
      if (menuType === 'top') {
        if (isAtPageTop) {
          updateValues('top', true, 'white', 'white', 0);
        } else {
          updateValues('sticky', true, PALETTE.text.primary, PALETTE.moeyBlue, 1);
        }
      } else if (menuType === 'sticky') {
        if (isAtPageTop) {
          const newType = (scrollY === 0) ? 'top' : 'sticky';
          updateValues(newType, true, whiteToGrey(ratio), whiteToMoeyBlue(ratio), ratio);
        } else {
          updateValues('sticky', true, PALETTE.text.primary, PALETTE.moeyBlue, 1);
        }
      }
    } else {
      if (menuType === 'top') {
        if (isAtPageTop) {
          updateValues('top', true, 'white', 'white', 0);
        } else {
          updateValues('sticky', false, PALETTE.text.primary, PALETTE.moeyBlue, 1);
        }
      } else if (menuType === 'sticky') {
        if (isAtPageTop) {
          const newType = (scrollY === 0) ? 'top' : 'sticky';
          updateValues(newType, true, whiteToGrey(ratio), whiteToMoeyBlue(ratio), ratio);
        } else {
          updateValues('sticky', false, PALETTE.text.primary, PALETTE.moeyBlue, 1);
        }
      }
    }
  });

  return {
    menuType,
    menuIsVisible,
    wasAtPageTop,
    color,
    accentColor,
    backgroundColor: `rgba(255, 255, 255, ${backgroundOpacity})`,
    menuHeight,
  };
}
