import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import HeaderNavMenuItem from './HeaderNavMenuItem';

const HeaderNavMenu = ({ color, accentColor, vertical, handleClickOnMenuItem }) => {
  const location = useLocation();

  return (
    <nav>
      <HeaderNavMenuItem
        label="ABOUT"
        to="/about"
        selected={location.pathname.startsWith('/about')}
        color={color}
        hoverColor={accentColor}
        inline={!vertical}
        margin={vertical ? '0 0 10px 0' : '0'}
        onClick={handleClickOnMenuItem}
      />
      <HeaderNavMenuItem
        label="PROJECTS"
        to="/projects"
        selected={location.pathname.startsWith('/projects')}
        color={color}
        hoverColor={accentColor}
        inline={!vertical}
        margin={vertical ? '0 0 10px 0' : '0 0 0 30px'}
        onClick={handleClickOnMenuItem}
      />
      <HeaderNavMenuItem
        label="PRODUCTS"
        to="/products"
        selected={location.pathname.startsWith('/products')}
        color={color}
        hoverColor={accentColor}
        inline={!vertical}
        margin={vertical ? '0 0 10px 0' : '0 0 0 30px'}
        onClick={handleClickOnMenuItem}
      />
      <HeaderNavMenuItem
        label="CONTACT"
        to="/contact"
        selected={location.pathname.startsWith('/contact')}
        color={color}
        hoverColor={accentColor}
        inline={!vertical}
        margin={vertical ? '0' : '0 0 0 30px'}
        onClick={handleClickOnMenuItem}
      />
    </nav>
  );
};

HeaderNavMenu.defaultProps = {
  vertical: false,
  handleClickOnMenuItem: () => {},
};

HeaderNavMenu.propTypes = {
  color: PropTypes.string.isRequired,
  accentColor: PropTypes.string.isRequired,
  vertical: PropTypes.bool,
  handleClickOnMenuItem: PropTypes.func,
};

export default HeaderNavMenu;
