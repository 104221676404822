import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import useBreakpoints from '@utils/useBreakpoints';
import ProjectThumbnailListItem from './ProjectThumbnailListItem';
import useStyles from './styles';

const ProjectThumbnailList = ({ projects, horizontalOnMobile, xl, lg, md, sm, xs }) => {
  const classes = useStyles({ horizontalOnMobile });
  const { smUp, mdUp, lgUp, xlUp } = useBreakpoints();
  const getNumber = (bp) => {
    /* eslint-disable no-fallthrough */
    switch (bp) {
      case 'xl':
        if (xl !== false) return parseInt(12 / xl, 10);
      case 'lg':
        if (lg !== false) return parseInt(12 / lg, 10);
      case 'md':
        if (md !== false) return parseInt(12 / md, 10);
      case 'sm':
        if (sm !== false) return parseInt(12 / sm, 10);
      case 'xs':
        if (xs !== false) return parseInt(12 / xs, 10);
      default:
        return 0;
    }
    /* eslint-enable no-fallthrough */
  };
  const numOfItemsInRow = xlUp ? getNumber('xl') : lgUp ? getNumber('lg') : mdUp
    ? getNumber('md') : smUp ? getNumber('sm') : getNumber('xs');

  return (
    <Grid
      container
      spacing={0}
      className={classes.root}
    >
      {projects.map((project, index) => (
        <Grid
          item
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          key={project.id}
        >
          <ProjectThumbnailListItem
            title={project.titleShort || project.title}
            client={project.clientNameShort || project.clientName}
            image={project.primaryImage.fluid}
            gradientColorLight={project.gradientColorLight}
            gradientColorDark={project.gradientColorDark}
            slug={project.slug}
            horizontalOnMobile={horizontalOnMobile}
            indexInRow={index % numOfItemsInRow}
          />
        </Grid>
      ))}
    </Grid>
  );
};

ProjectThumbnailList.defaultProps = {
  horizontalOnMobile: false,
  xl: false,
  lg: false,
  md: false,
  sm: false,
  xs: 12,
};

ProjectThumbnailList.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    titleShort: PropTypes.string,
    clientName: PropTypes.string.isRequired,
    clientNameShort: PropTypes.string,
    primaryImage: PropTypes.shape({
      title: PropTypes.string,
      fluid: GatsbyImagePropTypes.fluid,
    }).isRequired,
  })).isRequired,
  horizontalOnMobile: PropTypes.bool,
  xl: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  lg: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  md: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  sm: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  xs: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export default ProjectThumbnailList;
