import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import AmbiguousLink from '@components/common/AmbiguousLink';
import { motion } from 'framer-motion';
import { ANIM } from '@constants';
import useStyles from './styles';
import { rootVariants, buttonVariants, labelVariants, lineVariants } from './animations';

const CTAButton = ({
  label,
  width,
  to,
  noLine,
  thick,
  onClick,
  buttonType,
  disabled,
}) => {
  const classes = useStyles({ width, noLine, thick, disabled });
  const transition = { duration: ANIM.duration, ease: 'easeInOut' };

  return (
    <motion.div
      className={classes.root}
      variants={rootVariants({ disabled })}
      initial="rest"
      whileHover="hover"
      whileTap="pressed"
      transition={transition}
    >
      <AmbiguousLink to={to} className={[classes.link, 'no-decoration'].join(' ')}>
        <motion.button
          className={classes.button}
          onClick={onClick}
          type={buttonType}
          variants={buttonVariants()}
          transition={transition}
        >
          <motion.div
            variants={labelVariants({ noLine, disabled })}
            transition={transition}
          >
            <Typography
              variant={thick ? 'h3' : 'button'}
              component="span"
              className={classes.label}
            >
              { label }
            </Typography>
          </motion.div>
        </motion.button>
      </AmbiguousLink>
      {noLine ? null : (
        <motion.div
          className={classes.line}
          variants={lineVariants}
          transition={transition}
        />
      )}
    </motion.div>
  );
};

CTAButton.defaultProps = {
  to: '',
  noLine: false,
  thick: false,
  onClick: () => {},
  buttonType: 'button',
  disabled: false,
};

CTAButton.propTypes = {
  label: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  to: PropTypes.string,
  noLine: PropTypes.bool,
  thick: PropTypes.bool,
  onClick: PropTypes.func,
  buttonType: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CTAButton;
