import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { openMobileMenuOverlay, closeMobileMenuOverlay } from '@store/actions';
import { ThemeProvider } from '@material-ui/core/styles';
import useBreakpoints from '@utils/useBreakpoints';
import { Container } from '@material-ui/core';
import AppHeader from '@components/header/AppHeader';
import MobileNavOverlay from '@components/header/MobileNavOverlay';
import AppFooter from '@components/footer/AppFooter';
import defaultTheme from './default-theme';
import useStyles from './styles';

const Layout = ({ children }) => {
  const classes = useStyles();
  const mobileMenuIsOpen = useSelector((state) => state.showMobileMenuOverlay);
  const dispatch = useDispatch();
  const openMobileMenu = () => dispatch(openMobileMenuOverlay());
  const closeMobileMenu = () => dispatch(closeMobileMenuOverlay());
  const toggleMobileMenu = () => (mobileMenuIsOpen ? closeMobileMenu() : openMobileMenu());

  const { mdUp } = useBreakpoints();
  if (mdUp) dispatch(closeMobileMenuOverlay());

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppHeader
        handleClickOnMenuIcon={toggleMobileMenu}
        mobileMenuIsOpen={mobileMenuIsOpen}
      />
      <MobileNavOverlay
        handleClickOnMenuItem={closeMobileMenu}
        showOverlay={mobileMenuIsOpen}
      />
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.shadower}>
          <main className={classes.main}>
            {children}
          </main>
          <AppFooter />
        </div>
      </Container>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
