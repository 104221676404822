export default {
  duration: 0.4,
  projectThumbnailList: {
    easeMaskReveal: [0.77, 0, 0.175, 1],
    easeAppearance: [0.215, 0.61, 0.355, 1],
    stagger: 0.2,
    duration: 1.0,
    offset: '30%',
  },
  ImageRevealer: {
    mask: {
      duration: 0.6,
      ease: [0.77, 0, 0.175, 1],
      stagger: 0.5,
    },
    image: {
      duration: 1.0,
      ease: 'easeOut',
    },
  },
  easeOutQuick: [0, 0.67, 0, 1],
  easeOutCubic: [0.33, 1, 0.68, 1],
  easeInOutCubic: [0.65, 0, 0.35, 1],
  easeInOutQuad: [0.45, 0, 0.55, 1],
  easeInOutBack: [0.680, -0.550, 0.265, 1.550],
};
