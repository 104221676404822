export default {
  moeyBlue: '#00adee',
  moeyBlueRgba: (a) => `rgba(0, 173, 238, ${a})`,
  subtleAccentBackground: 'rgba(190, 152, 152, 0.05)',
  text: {
    primary: '#666666',
    secondary: '#ffffff',
    heading: '#333333',
  },
};
