import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const SectionSpacer = ({ xl, lg, md, sm, xs }) => {
  const classes = useStyles({ xl, lg, md, sm, xs });

  return (
    <div className={classes.root} />
  );
};

SectionSpacer.defaultProps = {
  xl: '',
  lg: '',
  md: '',
  sm: '',
  xs: '',
};

SectionSpacer.propTypes = {
  xl: PropTypes.string,
  lg: PropTypes.string,
  md: PropTypes.string,
  sm: PropTypes.string,
  xs: PropTypes.string,
};

export default SectionSpacer;
