import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    padding: '0',
    position: 'relative',
  },
  shadower: {
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
  },
  main: {
    position: 'relative',
  },
}));

export default useStyles;
