import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const CenteredSectionContainer = ({
  children, paddingXs, paddingSm, paddingMd, paddingLg, paddingXl,
  maxWidthXs, maxWidthSm, maxWidthMd, maxWidthLg, maxWidthXl, style,
}) => {
  const classes = useStyles({
    paddingXs, paddingSm, paddingMd, paddingLg, paddingXl, // eslint-disable-line
    maxWidthXs, maxWidthSm, maxWidthMd, maxWidthLg, maxWidthXl, // eslint-disable-line
  });

  return (
    <section className={classes.root} style={style}>
      <div className={classes.container}>
        { children }
      </div>
    </section>
  );
};

CenteredSectionContainer.defaultProps = {
  paddingXs: '',
  paddingSm: '',
  paddingMd: '',
  paddingLg: '',
  paddingXl: '',
  maxWidthXs: '',
  maxWidthSm: '',
  maxWidthMd: '',
  maxWidthLg: '',
  maxWidthXl: '',
  style: {},
};

CenteredSectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
  paddingXs: PropTypes.string,
  paddingSm: PropTypes.string,
  paddingMd: PropTypes.string,
  paddingLg: PropTypes.string,
  paddingXl: PropTypes.string,
  maxWidthXs: PropTypes.string,
  maxWidthSm: PropTypes.string,
  maxWidthMd: PropTypes.string,
  maxWidthLg: PropTypes.string,
  maxWidthXl: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line
};

export default CenteredSectionContainer;
