import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Fade } from '@material-ui/core';
import { ANIM } from '@constants';
import useStyles from './styles';

const FullScreenOverlay = ({
  children,
  showOverlay,
  overflowY,
  hasHeader,
  headerHeight,
  backgroundColor,
}) => {
  const classes = useStyles({ overflowY, headerHeight, backgroundColor });

  useEffect(() => {
    const addNoScrollClass = () => document.body.classList.add('no-scroll'); // eslint-disable-line
    const removeNoScrollClass = () => document.body.classList.remove('no-scroll'); // eslint-disable-line
    if (showOverlay) {
      addNoScrollClass();
    } else {
      removeNoScrollClass();
    }
    return removeNoScrollClass;
  }, [showOverlay]);

  return (
    <Fade in={showOverlay} timeout={ANIM.duration * 1000}>
      <div className={classes.root}>
        {hasHeader ? <div className={classes.overlayHeader} /> : null}
        <div className={classes.overlayBody}>
          { children }
        </div>
      </div>
    </Fade>
  );
};

FullScreenOverlay.defaultProps = {
  overflowY: 'auto',
  hasHeader: false,
  headerHeight: 0,
  backgroundColor: 'transparent',
};

FullScreenOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  showOverlay: PropTypes.bool.isRequired,
  overflowY: PropTypes.string,
  hasHeader: PropTypes.bool,
  headerHeight: PropTypes.number,
  backgroundColor: PropTypes.string,
};

export default FullScreenOverlay;
