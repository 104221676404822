import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: (props) => (props.horizontalOnMobile ? 'row' : ''),
      marginBottom: (props) => (props.horizontalOnMobile ? '0px' : '40px'),
    },
  },
}));

export default useStyles;
