import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import GatsbyBackgroundImage from 'gatsby-background-image';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import useStyles from './styles';

const PageHeader = ({ pageTitle, image }) => {
  const classes = useStyles();

  return (
    <GatsbyBackgroundImage
      fluid={image}
      className={classes.root}
    >
      <Typography variant="h1" color="textSecondary">
        { pageTitle }
      </Typography>
    </GatsbyBackgroundImage>
  );
};

PageHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  image: GatsbyImagePropTypes.fluid.isRequired,
};

export default PageHeader;
