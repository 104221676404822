import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  socialMediaIconContainer: {
    display: 'flex',
  },
  socialMediaIcon: {
    width: (props) => `${props.iconWidth}px`,
    height: (props) => `${props.iconWidth}px`,
    borderRadius: (props) => `${props.iconWidth / 2}px`,
    backgroundColor: 'white',
    cursor: 'pointer',
    marginRight: (props) => `${props.gap}px`,
    '& :last-child': {
      marginRight: '0',
    },
  },
}));

export default useStyles;
