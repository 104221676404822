import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    height: props.xl || '150px',
    [theme.breakpoints.down('lg')]: {
      height: props.lg || null,
    },
    [theme.breakpoints.down('md')]: {
      height: props.md || '120px',
    },
    [theme.breakpoints.down('sm')]: {
      height: props.sm || '80px',
    },
    [theme.breakpoints.down('xs')]: {
      height: props.xs || null,
    },
  }),
}));

export default useStyles;
