import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import useStyles from './styles';

const AppHeaderContainer = ({ children, fixed, show, hasTransition }) => {
  const classes = useStyles({ fixed, show, hasTransition });

  return (
    <Container maxWidth="xl" className={classes.headerContainer}>
      { children }
    </Container>
  );
};

AppHeaderContainer.defaultProps = {
  fixed: false,
  show: false,
  hasTransition: false,
};

AppHeaderContainer.propTypes = {
  children: PropTypes.node.isRequired,
  fixed: PropTypes.bool,
  show: PropTypes.bool,
  hasTransition: PropTypes.bool,
};

export default AppHeaderContainer;
