import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: props.paddingXl || '0 112px',
    [theme.breakpoints.down('lg')]: {
      padding: props.paddingLg
        || props.paddingXl
        || '',
    },
    [theme.breakpoints.down('md')]: {
      padding: props.paddingMd
        || props.paddingLg
        || props.paddingXl
        || '',
    },
    [theme.breakpoints.down('sm')]: {
      padding: props.paddingSm
        || props.paddingMd
        || props.paddingLg
        || props.paddingXl
        || '0 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: props.paddingXs
        || props.paddingSm
        || props.paddingMd
        || props.paddingLg
        || props.paddingXl
        || '0 25px',
    },
  }),
  container: (props) => ({
    width: '100%',
    maxWidth: props.maxWidthXl || '1000px',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      maxWidth: props.maxWidthLg
        || props.maxWidthXl
        || '',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: props.maxWidthMd
        || props.maxWidthLg
        || props.maxWidthXl
        || '800px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: props.maxWidthSm
        || props.maxWidthMd
        || props.maxWidthLg
        || props.maxWidthXl
        || '',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: props.maxWidthXs
        || props.maxWidthSm
        || props.maxWidthMd
        || props.maxWidthLg
        || props.maxWidthXl
        || '',
    },
  }),
}));

export default useStyles;
