import { useState, useEffect } from 'react';

const getWindowScrollValues = () => {
  let scrollX = 0;
  let scrollY = 0;
  let isScrollXAtEdge = false;
  let isScrollYAtEdge = false;
  if (typeof window !== 'undefined') {
    const { scrollX: sx, scrollY: sy, innerWidth, innerHeight } = window; // eslint-disable-line
    scrollX = sx;
    scrollY = sy;
    isScrollXAtEdge = (scrollX === 0) || (scrollX === innerWidth);
    isScrollYAtEdge = (scrollY === 0) || (scrollY === innerHeight);
  }
  return { scrollX, scrollY, isScrollXAtEdge, isScrollYAtEdge };
};

export default function useWindowScrollValues() {
  const [scrollX, setScrollX] = useState({
    current: 0,
    prev: 0,
    isScrollingForward: { current: false, prev: false },
    isScrollingBackward: { current: false, prev: false },
  });
  const [scrollY, setScrollY] = useState({
    current: 0,
    prev: 0,
    isScrollingForward: { current: false, prev: false },
    isScrollingBackward: { current: false, prev: false },
  });

  const handleScroll = () => {
    const {
      scrollX: newScrollX,
      scrollY: newScrollY,
      isScrollXAtEdge,
      isScrollYAtEdge,
    } = getWindowScrollValues();
    const threshold = 10;

    if (newScrollX !== scrollX.current) {
      const diff = newScrollX - scrollX.current;
      const updateScrollDir = Math.abs(diff) > threshold;
      if (updateScrollDir) {
        setScrollX({
          current: newScrollX,
          prev: scrollX.current,
          isScrollingForward: {
            current: !isScrollXAtEdge && (newScrollX > scrollX.current),
            prev: scrollX.isScrollingForward.current,
          },
          isScrollingBackward: {
            current: !isScrollYAtEdge && (scrollX.current > newScrollX),
            prev: scrollX.isScrollingBackward.current,
          },
        });
      } else {
        setScrollX((prevState) => ({
          ...prevState,
          current: newScrollX,
          prev: scrollX.current,
        }));
      }
    }

    if (newScrollY !== scrollY.current) {
      const diff = newScrollY - scrollY.current;
      const updateScrollDir = Math.abs(diff) > threshold;
      if (updateScrollDir) {
        setScrollY({
          current: newScrollY,
          prev: scrollY.current,
          isScrollingForward: {
            current: !isScrollYAtEdge && (newScrollY > scrollY.current),
            prev: scrollY.isScrollingForward.current,
          },
          isScrollingBackward: {
            current: !isScrollYAtEdge && (scrollY.current > newScrollY),
            prev: scrollY.isScrollingForward.current,
          },
        });
      } else {
        setScrollY((prevState) => ({
          ...prevState,
          current: newScrollY,
          prev: scrollY.current,
        }));
      }
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll); // eslint-disable-line
    return () => document.removeEventListener('scroll', handleScroll); // eslint-disable-line
  }, [scrollX.current, scrollY.current]);

  return {
    scrollX: scrollX.current,
    scrollY: scrollY.current,
    lastScrollX: scrollX.prev,
    lastScrollY: scrollY.prev,
    isScrollingRight: scrollX.isScrollingForward.current,
    isScrollingLeft: scrollX.isScrollingBackward.current,
    isScrollingDown: scrollY.isScrollingForward.current,
    isScrollingUp: scrollY.isScrollingBackward.current,
    lastIsScrollingRight: scrollX.isScrollingForward.prev,
    lastIsScrollingLeft: scrollX.isScrollingBackward.prev,
    lastIsScrollingDown: scrollY.isScrollingForward.prev,
    lastIsScrollingUp: scrollY.isScrollingForward.prev,
  };
}
