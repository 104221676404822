import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import useInViewAnimation from '@utils/useInViewAnimation';
import { motion } from 'framer-motion';
import { ANIM } from '@constants';
import ProjectThumbnailListItemImage from './ProjectThumbnailListItemImage';
import ProjectThumbnailListItemText from './ProjectThumbnailListItemText';
import useStyles from './styles';
import { rootVariants, containerVariants } from './animations';

const ProjectThumbnailListItem = ({
  title,
  client,
  image,
  gradientColorLight,
  gradientColorDark,
  slug,
  horizontalOnMobile,
  indexInRow,
}) => {
  const {
    context,
    AnimationContext,
    intersectionRef,
    enabled: hasAnimation,
    triggered: animationTriggered,
  } = useInViewAnimation();
  const classes = useStyles({ horizontalOnMobile });
  const delay = indexInRow * ANIM.projectThumbnailList.stagger;

  return (
    <AnimationContext.Provider value={context}>
      <Link to={`/projects/${slug}`} className="no-decoration">
        <motion.div
          ref={intersectionRef}
          animate={!hasAnimation || animationTriggered ? 'visible' : 'hidden'}
          variants={rootVariants(delay)}
        >
          <motion.div
            className={classes.container}
            variants={containerVariants(delay)}
          >
            <ProjectThumbnailListItemImage
              image={image}
              horizontalOnMobile={horizontalOnMobile}
              gradientColorLight={gradientColorLight}
              gradientColorDark={gradientColorDark}
              delay={delay}
            />
            <ProjectThumbnailListItemText
              title={title}
              client={client}
              gradientColorLight={gradientColorLight}
              gradientColorDark={gradientColorDark}
              horizontalOnMobile={horizontalOnMobile}
            />
          </motion.div>
        </motion.div>
      </Link>
    </AnimationContext.Provider>
  );
};

ProjectThumbnailListItem.defaultProps = {
  horizontalOnMobile: false,
  indexInRow: 0,
};

ProjectThumbnailListItem.propTypes = {
  title: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  image: GatsbyImagePropTypes.fluid.isRequired,
  gradientColorLight: PropTypes.string.isRequired,
  gradientColorDark: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  horizontalOnMobile: PropTypes.bool,
  indexInRow: PropTypes.number,
};

export default ProjectThumbnailListItem;
