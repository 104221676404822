export const rootVariants = ({ disabled }) => ({
  rest: { letterSpacing: '-1px' },
  hover: { letterSpacing: disabled ? '-1px' : '1px' },
});
export const buttonVariants = () => ({
  rest: { boxShadow: '0 10px 20px 0px rgba(0, 0, 0, 0.1)' },
  pressed: { boxShadow: '0 10px 20px -10px rgba(0, 0, 0, 0.1)' },
});
export const labelVariants = ({ noLine }) => ({
  rest: { translateX: '0%' },
  hover: { translateX: noLine ? '0%' : '-10%' },
});
export const lineVariants = {
  rest: { scaleX: 1 },
  hover: { scaleX: 0.8 },
};
