import React from 'react';
import PropTypes from 'prop-types';
import FacebookIcon from '@images/facebook-icon.inline.svg';
import TwitterIcon from '@images/twitter-icon.inline.svg';
import YoutubeIcon from '@images/youtube-icon.inline.svg';
import useStyles from './styles';

const SocialMediaIconContainer = ({
  twitter,
  facebook,
  youtube,
  twitterURL,
  facebookURL,
  youtubeURL,
  handleClickOnTwitterIcon,
  handleClickOnFacebookIcon,
  handleClickOnYoutubeIcon,
  iconWidth,
  gap,
  className,
}) => {
  const classes = useStyles({ iconWidth, gap });

  const FB = () => (
    <div className={classes.socialMediaIcon}>
      <FacebookIcon
        onClick={handleClickOnFacebookIcon}
      />
    </div>
  );
  const TW = () => (
    <div className={classes.socialMediaIcon}>
      <TwitterIcon
        onClick={handleClickOnTwitterIcon}
      />
    </div>
  );
  const YT = () => (
    <div className={classes.socialMediaIcon}>
      <YoutubeIcon
        onClick={handleClickOnYoutubeIcon}
      />
    </div>
  );

  return (
    <div className={`${classes.socialMediaIconContainer} ${className}`}>
      {!facebook ? null : facebookURL ? (
        <a href={facebookURL} target="_blank" rel="noopener noreferrer">
          <FB />
        </a>
      ) : <FB />}
      {!twitter ? null : twitterURL ? (
        <a href={twitterURL} target="_blank" rel="noopener noreferrer">
          <TW />
        </a>
      ) : <TW />}
      {!youtube ? null : youtubeURL ? (
        <a href={youtubeURL} target="_blank" rel="noopener noreferrer">
          <YT />
        </a>
      ) : <YT />}
    </div>
  );
};

SocialMediaIconContainer.defaultProps = {
  twitter: false,
  facebook: false,
  youtube: false,
  twitterURL: '',
  facebookURL: '',
  youtubeURL: '',
  handleClickOnTwitterIcon: () => {},
  handleClickOnFacebookIcon: () => {},
  handleClickOnYoutubeIcon: () => {},
  gap: 0,
  className: '',
};

SocialMediaIconContainer.propTypes = {
  twitter: PropTypes.bool,
  facebook: PropTypes.bool,
  youtube: PropTypes.bool,
  twitterURL: PropTypes.string,
  facebookURL: PropTypes.string,
  youtubeURL: PropTypes.string,
  handleClickOnTwitterIcon: PropTypes.func,
  handleClickOnFacebookIcon: PropTypes.func,
  handleClickOnYoutubeIcon: PropTypes.func,
  iconWidth: PropTypes.number.isRequired,
  gap: PropTypes.number,
  className: PropTypes.string,
};

export default SocialMediaIconContainer;
