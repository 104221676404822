import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const MoeyContact = ({ color }) => (
  <Typography variant="body2" color={color}>
    <a href="tel:1-718-237-1496" className="no-decoration">
      T: (718) 237-1496
    </a>
    <br />
    F: (718) 865-8212
    <br />
    <a href="mailto:studio@moeyinc.com" className="no-decoration">
      M: studio@moeyinc.com
    </a>
  </Typography>
);

MoeyContact.defaultProps = {
  color: '',
};

MoeyContact.propTypes = {
  color: PropTypes.string,
};

export default MoeyContact;
