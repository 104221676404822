import { makeStyles } from '@material-ui/core/styles';
import { PALETTE } from '@constants';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    letterSpacing: '-1px',
    width: (props) => props.width,
    paddingLeft: (props) => (props.noLine ? '0' : '20px'),
  },
  button: {
    width: '100%',
    border: 'none',
    backgroundColor: (props) => (props.disabled ? 'lightgray' : 'white'),
    borderRadius: '4px',
    boxShadow: (props) => (props.disabled ? 'none' : '0 10px 20px rgba(0, 0, 0, 0.1)'),
    cursor: (props) => (props.disabled ? 'default' : 'pointer'),
    opacity: (props) => (props.disabled ? 0.8 : 1.0),
    letterSpacing: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 0,
    height: (props) => (props.thick ? '100px' : '60px'),
    paddingLeft: (props) => (props.noLine ? '0' : '60px'),
    [theme.breakpoints.down('md')]: {
      height: (props) => (props.thick ? '80px' : '50px'),
      paddingLeft: (props) => (props.noLine ? '0' : '50px'),
    },
    [theme.breakpoints.down('sm')]: {
      height: (props) => (props.thick ? '60px' : '44px'),
      paddingLeft: (props) => (props.noLine ? '0' : '30px'),
    },
    outline: 'none',
  },
  label: {
    color: (props) => (props.disabled ? 'darkgray' : props.thick ? PALETTE.moeyBlue : ''),
  },
  line: {
    position: 'absolute',
    zIndex: '100',
    top: '50%',
    left: '0',
    transform: 'translateY(-50%)',
    transformOrigin: '0% 50%',
    width: '80px',
    height: '2px',
    backgroundColor: PALETTE.moeyBlue,
    [theme.breakpoints.down('md')]: {
      width: '70px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50px',
    },
  },
}));

export default useStyles;
