import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';

const FooterButton = ({ className, textAlign, subscriptionForm, label }) => (
  <div align={textAlign}>
    <Button className={className} variant="contained" disableElevation="true" color="primary">
      <a href={subscriptionForm} target="_blank" rel="noopener noreferrer" className="no-decoration">
        <Typography variant="h5" color="textSecondary">
          {label}
        </Typography>
      </a>
    </Button>
  </div>
);

FooterButton.defaultProps = {
  textAlign: 'left',
  className: '',
  subscriptionForm: '',
  label: '',
};

FooterButton.propTypes = {
  className: PropTypes.string,
  textAlign: PropTypes.string,
  subscriptionForm: PropTypes.string,
  label: PropTypes.string,
};

export default FooterButton;
