import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: (props) => (props.horizontalOnMobile ? '40px' : ''),
    },
  },
}));

export default useStyles;
