import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import AnimationContext from '@utils/AnimationContext';
import useBreakpoints from '@utils/useBreakpoints';

export default function useInViewAnimation(options) {
  let threshold = 0;
  let disabledOnMobile = true;
  let waitUntilManuallyTriggered = false;
  if (options && typeof options === 'object') {
    if (options.threshold) threshold = options.threshold;
    if (options.disabledOnMobile) disabledOnMobile = options.disabledOnMobile;
    if (options.waitUntilManuallyTriggered) {
      waitUntilManuallyTriggered = options.waitUntilManuallyTriggered;
    }
  }

  const { xsDown } = useBreakpoints();
  const [triggered, setTriggered] = useState(false);
  const [readyForTriggering, setReadyForTriggering] = useState(!waitUntilManuallyTriggered);
  const [enabled, setEnabled] = useState(false);
  const [intersectionRef, inView] = useInView({ triggerOnce: true, threshold });
  const [context, updateContext] = useState({ enabled, triggered });

  useEffect(() => {
    if (readyForTriggering && inView) setTriggered(true);
  }, [readyForTriggering, inView]);

  useEffect(() => {
    if (disabledOnMobile && xsDown) {
      setEnabled(false);
    } else {
      setEnabled(true);
    }
  }, [disabledOnMobile, xsDown, setEnabled]);

  useEffect(() => {
    updateContext({ enabled, triggered });
  }, [enabled, triggered, updateContext]);

  return {
    context,
    AnimationContext,
    triggered,
    enabled,
    intersectionRef,
    trigger: () => setReadyForTriggering(true),
  };
}
