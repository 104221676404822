import React from 'react';
import PropTypes from 'prop-types';
import LogoIcon from '@images/moey-logo.inline.svg';
import useStyles from './styles';

const MoeyLogo = ({ width, height, fill, className }) => {
  const classes = useStyles({ width, height, fill });

  return (
    <LogoIcon className={`${classes.root} ${className}`} />
  );
};

MoeyLogo.defaultProps = {
  width: 'auto',
  height: 'auto',
  fill: 'white',
  className: '',
};

MoeyLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default MoeyLogo;
