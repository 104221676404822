import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 375,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    text: {
      primary: '#666666',
      secondary: '#ffffff',
      heading: '#333333',
    },
    primary: {
      main: '#00adee',
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: '"DM Sans", sans-serif',
  },
});

theme.typography.h1 = {
  fontFamily: '"CoreCircus", verdana, sans-serif',
  fontSize: '3rem',
  fontWeight: 'normal',
  lineHeight: '4rem',
  color: '#333333',
  [theme.breakpoints.up('sm')]: {
    fontSize: '4rem',
    lineHeight: '5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '5rem',
    lineHeight: '6rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '7.2rem',
    lineHeight: '8rem',
  },
};

theme.typography.h2 = {
  fontFamily: '"CoreCircus", verdana, sans-serif',
  fontSize: '2.4rem',
  fontWeight: 'normal',
  lineHeight: '3rem',
  color: '#333333',
  [theme.breakpoints.up('md')]: {
    fontSize: '3.2rem',
    lineHeight: '4.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '4.8rem',
    lineHeight: '6.0rem',
  },
};

theme.typography.h3 = {
  fontFamily: '"CoreCircus", verdana, sans-serif',
  fontSize: '2.2rem',
  fontWeight: 'normal',
  lineHeight: '2.8rem',
  color: '#333333',
  [theme.breakpoints.up('md')]: {
    fontSize: '2.8rem',
    lineHeight: '4.0rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3.6rem',
    lineHeight: '4.8rem',
  },
};

theme.typography.h4 = {
  fontFamily: '"DM Sans", sans-serif',
  fontSize: '2rem',
  fontWeight: 'bold',
  lineHeight: '2.4rem',
  color: '#333333',
  [theme.breakpoints.up('md')]: {
    fontSize: '2.2rem',
    lineHeight: '2.6rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.4rem',
    lineHeight: '3.0rem',
  },
};

theme.typography.h5 = {
  fontFamily: '"DM Sans", sans-serif',
  fontSize: '1.4rem',
  fontWeight: 'bold',
  letterSpacing: '0.075rem',
  lineHeight: '2rem',
  color: '#333333',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.8rem',
    lineHeight: '2.8rem',
  },
};

theme.typography.button = {
  fontFamily: '"CoreCircus", verdana, sans-serif',
  fontSize: '2rem',
  fontWeight: 'normal',
  lineHeight: '2.4rem',
  color: '#00adee',
  [theme.breakpoints.up('md')]: {
    fontSize: '2.2rem',
    lineHeight: '2.4rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.4rem',
    lineHeight: '3rem',
  },
};

theme.typography.body1 = {
  fontFamily: '"DM Sans", sans-serif',
  fontSize: '2rem',
  fontWeight: 'normal',
  letterSpacing: '0.025rem',
  lineHeight: '3rem',
  color: '#666666',
  [theme.breakpoints.up('md')]: {
    fontSize: '2.2rem',
    lineHeight: '3.2rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.4rem',
    lineHeight: '3.6rem',
  },
};

theme.typography.body2 = {
  fontFamily: '"DM Sans", sans-serif',
  fontSize: '1.4rem',
  fontWeight: 'normal',
  letterSpacing: '0.025rem',
  lineHeight: '2.2rem',
  color: '#666666',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
    lineHeight: '2.4rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.6rem',
    lineHeight: '2.6rem',
  },
};

export default theme;
