import React from 'react';
import PropTypes from 'prop-types';
import useBreakpoints from '@utils/useBreakpoints';
import useAnimationContext from '@utils/useAnimationContext';
import { motion } from 'framer-motion';
import useStyles from './styles';
import { colorMaskVariants, whiteMaskVariants } from './animations';

const ProjectThumbnailListItemImageMask = ({
  horizontalOnMobile,
  gradientColorLight,
  gradientColorDark,
  delay,
}) => {
  const { enabled: hasAnimation, triggered: animationTriggered } = useAnimationContext();
  const { smDown } = useBreakpoints();
  const thickness = smDown ? 8 : 10;
  const vertical = horizontalOnMobile && smDown;
  const classes = useStyles({
    horizontalOnMobile,
    vertical,
    thickness,
    gradientColorLight,
    gradientColorDark,
  });

  return (
    <motion.div
      className={classes.maskContainer}
      animate={!hasAnimation || animationTriggered ? 'revealed' : 'visible'}
    >
      <motion.div
        key={`white-mask-${vertical}-${thickness}`}
        className={classes.whiteMask}
        initial={!hasAnimation ? 'revealed' : 'visible'}
        variants={whiteMaskVariants({ delay, vertical })}
      />
      <motion.div
        key={`color-mask-${vertical}-${thickness}`}
        className={classes.colorMask}
        initial={!hasAnimation ? 'revealed' : 'visible'}
        variants={colorMaskVariants({ delay, thickness, vertical })}
      />
    </motion.div>
  );
};

ProjectThumbnailListItemImageMask.defaultProps = {
  horizontalOnMobile: false,
  delay: 0,
};

ProjectThumbnailListItemImageMask.propTypes = {
  horizontalOnMobile: PropTypes.bool,
  gradientColorLight: PropTypes.string.isRequired,
  gradientColorDark: PropTypes.string.isRequired,
  delay: PropTypes.number,
};

export default ProjectThumbnailListItemImageMask;
