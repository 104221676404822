import React from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '@images/menu.inline.svg';
import CloseIcon from '@images/close.inline.svg';
import useStyles from './styles';

const MobileHeaderMenuIcon = ({ fill, mobileMenuIsOpen, onClick }) => {
  const classes = useStyles({ fill });
  return (
    <>
      {mobileMenuIsOpen ? (
        <CloseIcon
          className={classes.closeIcon}
          onClick={onClick}
        />
      ) : (
        <MenuIcon
          className={classes.menuIcon}
          onClick={onClick}
        />
      )}
    </>
  );
};

MobileHeaderMenuIcon.propTypes = {
  fill: PropTypes.string.isRequired,
  mobileMenuIsOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MobileHeaderMenuIcon;
