import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '420px',
    [theme.breakpoints.down('md')]: {
      height: '336px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '242px',
    },
  },
  neonImageContainer: {
    position: 'absolute',
    width: '100%',
    height: '200px',
    zIndex: '100',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      height: '160px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '120px',
    },
  },
  neonVideo: {
    height: '100%',
    width: '1000px',
    [theme.breakpoints.down('md')]: {
      width: '800px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '600px',
    },
  },
  imageBannerContainer: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    width: '100%',
    height: '250px',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      height: '200px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '140px',
    },
  },
  imageBanner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
