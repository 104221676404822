import { ANIM } from '@constants';

const duration = ANIM.projectThumbnailList.duration;
const ease = ANIM.projectThumbnailList.easeAppearance;

export const rootVariants = (delay) => ({
  hidden: { translateY: ANIM.projectThumbnailList.offset },
  visible: {
    translateY: 0,
    transition: { duration, delay, ease },
  },
});

export const containerVariants = (delay) => ({
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration, delay, ease },
  },
});
