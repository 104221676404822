import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const MoeyAddress = ({ color, addressInThreeLines }) => (
  <Typography variant="body2" color={color}>
    {addressInThreeLines ? (
      <>
        232 3rd Street
        <br />
        Suite E301
        <br />
        Brooklyn, NY 11215
        <br />
      </>
    ) : (
      <>
        232 3rd Street, Suite E301, Brooklyn, NY 11215
      </>
    )}
    <br />
    <a href="https://goo.gl/maps/RdBdS8ubbSUGSYYZA" target="_blank" rel="noopener noreferrer">
      Google Map
    </a>
  </Typography>
);

MoeyAddress.defaultProps = {
  color: '',
  addressInThreeLines: false,
};

MoeyAddress.propTypes = {
  color: PropTypes.string,
  addressInThreeLines: PropTypes.bool,
};

export default MoeyAddress;
