import { makeStyles } from '@material-ui/core/styles';
import { ANIM } from '@constants';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: (props) => props.backgroundColor,
    transition: (props) => (props.hasTransition ? `background-color ${ANIM.duration}s` : 'none'),
    height: (props) => props.height,
    padding: '70px 70px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '41px 30px 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '25px  25px 0',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
}));

export default useStyles;
