import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5rem 0',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '4rem 0',
    },
  },
  subtitle: {
    fontSize: '2.2rem',
    marginLeft: '1.4rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      marginLeft: '1rem',
    },
  },
}));

export default useStyles;
