import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '75px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '60px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '80px',
    },
  },
}));

export default useStyles;
