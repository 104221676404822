import { makeStyles } from '@material-ui/core/styles';
import { STYLES } from '@constants';

const useStyles = makeStyles(() => ({
  maskContainer: {
    zIndex: 100,
    ...STYLES.absFit,
    width: (props) => (props.vertical ? `calc(100% + ${props.thickness}px)` : '100%'),
    height: (props) => (props.vertical ? '100%' : `calc(100% + ${props.thickness}px)`),
  },
  whiteMask: {
    position: 'absolute',
    zIndex: 100,
    top: (props) => (props.vertical ? 0 : 'auto'),
    left: (props) => (props.vertical ? 'auto' : 0),
    right: 0,
    bottom: 0,
    height: (props) => (props.vertical ? '100%' : '0%'),
    width: (props) => (props.vertical ? '0%' : '100%'),
    background: 'white',
  },
  colorMask: {
    position: 'absolute',
    zIndex: 0,
    top: (props) => (props.vertical ? 0 : 'auto'),
    left: (props) => (props.vertical ? 'auto' : 0),
    right: 0,
    bottom: 0,
    height: (props) => (props.vertical ? '100%' : `${props.thickness}px`),
    width: (props) => (props.vertical ? `${props.thickness}px` : '100%'),
    background: (props) => (props.vertical
      ? `linear-gradient(180deg, ${props.gradientColorLight} 0%, ${props.gradientColorDark} 100%)`
      : `linear-gradient(90deg, ${props.gradientColorLight} 0%, ${props.gradientColorDark} 100%)`),
  },
}));

export default useStyles;
