import { makeStyles } from '@material-ui/core/styles';
import zIndexValues from '@styles/z-index-values';
import { ANIM } from '@constants';

const useStyles = makeStyles(() => ({
  headerContainer: {
    top: 0,
    left: 0,
    right: 0,
    padding: 0,
    zIndex: zIndexValues.OVERLAY + 1,
    position: (props) => (props.fixed ? 'fixed' : 'absolute'),
    transform: (props) => (props.show ? 'translateY(0)' : 'translateY(-100%)'),
    transition: (props) => (props.hasTransition ? `transform ${ANIM.duration}s` : ''),
  },
}));

export default useStyles;
