/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import useData from './data';

function SEO({
  description, lang, meta, title,
}) {
  const {
    site,
    aboutHeaderImage,
    projectsHeaderImage,
    productsHeaderImage,
    contactHeaderImage,
  } = useData();
  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, shrink-to-fit=no',
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
      link={[
        {
          rel: 'preload',
          as: 'image',
          href: aboutHeaderImage.childImageSharp.fluid.src,
        },
        {
          rel: 'preload',
          as: 'image',
          href: projectsHeaderImage.childImageSharp.fluid.src,
        },
        {
          rel: 'preload',
          as: 'image',
          href: productsHeaderImage.childImageSharp.fluid.src,
        },
        {
          rel: 'preload',
          as: 'image',
          href: contactHeaderImage.childImageSharp.fluid.src,
        },
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
