import { makeStyles } from '@material-ui/core/styles';
import { STYLES } from '@constants';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '75%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingTop: (props) => (props.horizontalOnMobile ? 'inherit' : ''),
      width: (props) => (props.horizontalOnMobile ? '200px' : ''),
      height: (props) => (props.horizontalOnMobile ? '150px' : ''),
      minWidth: (props) => (props.horizontalOnMobile ? '200px' : ''),
      maxHeight: (props) => (props.horizontalOnMobile ? '150px' : ''),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: (props) => (props.horizontalOnMobile ? '' : '56.25%'),
      width: (props) => (props.horizontalOnMobile ? '120px' : ''),
      height: (props) => (props.horizontalOnMobile ? '90px' : ''),
      minWidth: (props) => (props.horizontalOnMobile ? '120px' : ''),
      maxHeight: (props) => (props.horizontalOnMobile ? '90px' : ''),
    },
    '&:hover $thumbnail': {
      [theme.breakpoints.up('md')]: {
        transform: 'scale(1.2)',
      },
    },
  },
  thumbnailWrapper: {
    zIndex: 0,
    ...STYLES.absFit,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    transition: 'transform 0.8s',
  },
}));

export default useStyles;
