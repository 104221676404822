import { useStaticQuery, graphql } from 'gatsby';

const useData = () => useStaticQuery(
  graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
      aboutHeaderImage: file(relativePath: {eq: "page-header-about.jpg"}) {
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      projectsHeaderImage: file(relativePath: {eq: "page-header-projects.jpg"}) {
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      productsHeaderImage: file(relativePath: {eq: "page-header-products.jpg"}) {
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      contactHeaderImage: file(relativePath: {eq: "page-header-contact.jpg"}) {
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `,
);

export default useData;
