import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import FadeSlideUp from '@components/common/FadeSlideUp';
import useStyles from './styles';

const SectionTitle = ({ children, subtitle, delay }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h3">
        <FadeSlideUp offset="40px" delay={delay}>
          { children }
          {subtitle
            ? (
              <span className={classes.subtitle}>
                { subtitle }
              </span>
            )
            : null}
        </FadeSlideUp>
      </Typography>
    </div>
  );
};

SectionTitle.defaultProps = {
  subtitle: '',
  delay: 0,
};

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
  subtitle: PropTypes.string,
  delay: PropTypes.number,
};

export default SectionTitle;
