import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    position: 'relative',
    padding: '3rem 2rem',
    [theme.breakpoints.down('sm')]: {
      padding: (props) => (props.horizontalOnMobile ? '1.5rem 2rem' : ''),
    },
    [theme.breakpoints.down('xs')]: {
      padding: (props) => (props.horizontalOnMobile ? '0 2rem' : ''),
      display: (props) => (props.horizontalOnMobile ? 'flex' : ''),
      flexDirection: (props) => (props.horizontalOnMobile ? 'column' : ''),
      justifyContent: (props) => (props.horizontalOnMobile ? 'center' : ''),
    },
  },
  clientName: {
    marginBottom: '0.8rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: (props) => (props.horizontalOnMobile ? '0' : ''),
    },
  },
  colorBar: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: (props) => (props.vertical ? '0' : ''),
    right: (props) => (props.vertical ? 'auto' : ''),
    height: (props) => (props.vertical ? '100%' : `${props.thickness}px`),
    width: (props) => (props.vertical ? `${props.thickness}px` : '100%'),
    background: (props) => (props.vertical
      ? `linear-gradient(180deg, ${props.gradientColorLight} 0%, ${props.gradientColorDark} 100%)`
      : `linear-gradient(90deg, ${props.gradientColorLight} 0%, ${props.gradientColorDark} 100%)`),
  },
}));

export default useStyles;
