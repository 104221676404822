import { makeStyles } from '@material-ui/core/styles';
import { ANIM } from '@constants';

const useStyles = makeStyles(() => ({
  root: {
    width: (props) => (props.width),
    height: (props) => (props.height),
    fill: (props) => (props.fill),
    transition: `fill ${ANIM.duration}s`,
  },
}));

export default useStyles;
