import { makeStyles } from '@material-ui/core/styles';
import zIndexValues from '@styles/z-index-values';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    zIndex: zIndexValues.OVERLAY,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: '100%',
  },
  overlayHeader: {
    position: 'fixed',
    zIndex: zIndexValues.OVERLAY,
    top: 0,
    left: 0,
    right: 0,
    height: (props) => `${props.headerHeight}px`,
    backgroundColor: (props) => props.backgroundColor,
  },
  overlayBody: {
    position: 'fixed',
    zIndex: zIndexValues.OVERLAY,
    top: (props) => `${props.headerHeight}px`,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: (props) => props.backgroundColor,
    overflowY: (props) => props.overflowY,
  },
}));

export default useStyles;
