import React from 'react';
import PropTypes from 'prop-types';
import FullScreenOverlay from '@components/common/FullScreenOverlay';
import useHeaderMenuHeight from '@utils/useHeaderMenuHeight';
import AppFooter from '@components/footer/AppFooter';
import { PALETTE } from '@constants';
import HeaderNavMenu from '../HeaderNavMenu';
import useStyles from './styles';

const MobileNavOverlay = ({ showOverlay, handleClickOnMenuItem }) => {
  const menuHeight = useHeaderMenuHeight();
  const classes = useStyles();

  return (
    <FullScreenOverlay
      showOverlay={showOverlay}
      backgroundColor={PALETTE.moeyBlueRgba(0.9)}
      hasHeader
      headerHeight={menuHeight}
    >
      <div className={classes.root}>
        <div className={classes.menuContainer}>
          <HeaderNavMenu
            color="white"
            accentColor="white"
            vertical
            handleClickOnMenuItem={handleClickOnMenuItem}
          />
        </div>
        <AppFooter
          noLogo
          whiteText
          noFooterNote
          noFooterButton
        />
      </div>
    </FullScreenOverlay>
  );
};

MobileNavOverlay.propTypes = {
  showOverlay: PropTypes.bool.isRequired,
  handleClickOnMenuItem: PropTypes.func.isRequired,
};

export default MobileNavOverlay;
