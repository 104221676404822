import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const AmbiguousLink = ({ to, href, children, className }) => (
  <>
    {to ? (
      <Link to={to} className={className}>
        { children }
      </Link>
    ) : href ? (
      <a href={href} target="_blank" rel="noopener noreferrer" className={className}>
        { children }
      </a>
    ) : (
      <>
        { children }
      </>
    )}
  </>
);

AmbiguousLink.defaultProps = {
  to: '',
  href: '',
  className: '',
};

AmbiguousLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default AmbiguousLink;
