import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const FooterNote = ({ color, textAlign, className }) => (
  <div className={className}>
    <Typography variant="h5" color={color} align={textAlign}>
      Moey is a certified woman-owned and LGBTQ-owned business.
    </Typography>
  </div>
);

FooterNote.defaultProps = {
  color: '',
  textAlign: 'left',
  className: '',
};

FooterNote.propTypes = {
  color: PropTypes.string,
  textAlign: PropTypes.string,
  className: PropTypes.string,
};

export default FooterNote;
